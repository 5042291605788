import React from "react";
import MediaQuery from "react-responsive";

import "../styles/aboutUs.css";
import "../styles/responsive/responsive_aboutUs.css"


class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div id="aboutus" name="aboutus" className="page">
                <div id="aboutus1-left">
                    <img className="aboutus1-bg" src={require("../assets/about1.png")} />
                    <img className="aboutus1-picture" src= {require("../assets/about11.png")}/>
                </div>
                <div id="aboutus1-right">
                    <div className="aboutus1-title">
                        <div className="aboutus-title-first green-text">Learn Much</div>
                        <div className="aboutus-title-second green-text">ABOUT US</div>
                    </div>
                    <div className="aboutus1-sub-title">Creativity and Imagination</div>
                    <div className="aboutus1-description-text">
                        &nbsp;  became our compass at the beginning of our adventure.
                        In 2016, we came together in İzmir and created Furtle Game.
                        In each of our games, we tried to recreate the excitement 
                        of playing a new game that we felt as children
                    </div>
                </div>

                <div>
                    <MediaQuery minWidth={600}>
                        <img className="pacman" src={require("../assets/pacman.png")} />
                    </MediaQuery>
                    <MediaQuery maxWidth={600}>
                        <img className="pacman" src={require("../assets/pacman_mobile.png")} />
                    </MediaQuery>
                </div>

                <div id="aboutus2-left">
                    <div className="aboutus2-sub-title">Friendship and Team Spirit</div>
                    <div className="aboutus2-description-text">
                        &nbsp; always been what we strived for. Our team is
                        led by love, trust and in company of one another
                        and we embody our feelings with the games
                        we make.
                    </div>
                </div>
                <div id="aboutus2-right">
                    <img className="aboutus2-picture" src= {require("../assets/about21.png")}/>
                    <img className="aboutus2-bg" src={require("../assets/about2222@2x.png")} />
                </div>
            </div>
        );
    }
}


export default AboutUs;
