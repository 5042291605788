import React from "react";

import "../styles/contact.css";
import "../styles/responsive/responsive_contact.css"

import Form from 'react-bootstrap/Form'
import { Row, Col, Button } from 'react-bootstrap'

const scriptUrl = "https://script.google.com/macros/s/AKfycbxQzzdRbdDdLRst_cfBqmv0YE1M0Qg4rb9fbe3I30TNwi3IpY_jSUUByfGCsx5ECje-Ag/exec";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            loading: false,
            sended: false
        };

        this.formRef = React.createRef();
    }

    sendForm = (e) => {
        this.setState({ loading: true });
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ loading: false });
        }
        
        this.setState({validated: true});

        if (form.checkValidity() === true) {
            e.preventDefault();

            fetch(scriptUrl, {method: 'POST', body: new FormData(this.formRef.current)})
            .then(res => {
                this.setState({ loading: false, sended: true });
                if (res.status === 200) {
                    alert("Thank you for your message! We will get back to you soon!");
                } else {
                    alert("There was an error sending your message. Please try again later or contact at contact@furtlegame.com");
                }
            })
            .catch(err => console.log(err))
        }
    }

    openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    render() {
        return (
            <div id="contact" name="contact" className="page">
                <div className="contact-title">
                    <div className="contact-title-first green-text">Get In Touch</div>
                    <div className="contact-title-second green-text">CONTACT US</div>
                </div>

                <div className="contact-container">
                    <div id="contact-description" className="contact-description">
                        <div className="contact-description-title">
                            &nbsp;Have an inquiry or some <br />
                            feedback for us?
                        </div>
                        <div className="contact-location-text">
                            <div id="contact-location-left">
                                <img className="contact-location-icon" src={require("../assets/location_icon.png")} />
                            </div>
                            <div id="contact-location-right">
                                <div className="contact-location-title green-text">ADDRESS</div>
                                <div className="contact-location-description">Adatepe Mah. Doğuş Cad. <br />
                                    No:207 AG Depark Beta Binası <br />
                                    Ofis: B25 Buca/İzmir
                                </div>
                            </div>
                            <div>
                                <img className="contact-map" src={require("../assets/group254.png")} onClick={() => this.openInNewTab("https://www.google.com/maps/place/Furtle+Game/@38.366083,27.2070967,15z/data=!4m5!3m4!1s0x0:0x185052bf1e335606!8m2!3d38.366083!4d27.2070967")} />
                            </div>
                        </div>
                    </div>
                    <Form noValidate validated={this.state.validated} ref={this.formRef} id="contact-form" onSubmit={this.sendForm} name="google-sheet">
                        <Row>
                            <Col xs={6}>
                                <Form.Control required className="box firstnamebox" size="sm" placeholder="First name*" id="name"  name="name"/>
                            </Col>
                            <Col xs={6}>
                                <Form.Control required className="box secondnamebox" size="sm" placeholder="Last name*" id="surname"  name="surname"/>
                            </Col>
                        </Row>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Col sm={8}>
                                <Form.Control required className="box" size="sm" type="email" placeholder="Email Adress*" id="email"  name="email"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="message">
                            <Col sm={8}>
                                <Form.Control required className="box messagebox" size="sm" as="textarea" rows={5} placeholder="Your message*" id="message"  name="message"/>
                            </Col>
                        </Form.Group>
                        <Button className="send-button" size="lg" type="submit" disabled={this.state.loading || this.state.sended} >
                            {this.state.loading ? 'SENDING...' : this.state.sended ? 'THANK YOU!' : 'SEND MESSAGE'}
                        </Button>
                    </Form>
                    <div id="contact-phone-image">
                        <img className="contact-phone-image" src={require("../assets/phone.png")} />
                    </div>
                </div>
            </div>
        );
    }
}

Contact.propTypes = {

}

Contact.defaultProps = {

}


export default Contact;
