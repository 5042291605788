import React, { createRef } from "react";
import PropTypes from "prop-types";
import Slider from 'react-slick';
import {Button, Container} from 'react-bootstrap'
import MobileStoreButton from 'react-mobile-store-button'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../styles/ourGames.css";
import "../styles/responsive/responsive_ourGames.css"

var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    centerMode: true,
    arrows: false,
  };

class OurGames extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }

        this.slider = createRef();
    }

    changeActiveImage = (oldIndex, newIndex) =>
    {
        this.setState({activeIndex: newIndex});
    }

    render() {
        return (
            <div id="ourgames" name="ourgames" className="page games-page">
                <div className="games-title">
                    <div className="games-title-first green-text">Our</div>
                    <div className="games-title-second green-text">GAMES</div>
                </div>
                <div id="games-bg">
                <Slider
                    ref={this.slider}
                    className="game-carousel"
                    {...settings}
                    beforeChange={this.changeActiveImage}
                >
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 0 ? "activeGame" : ""}`} src={require("../assets/gameLogos/goldpanning_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 0 ? "activeGameText" : ""}`}>GOLD PANNING!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 1 ? "activeGame" : ""}`} src={require("../assets/gameLogos/bakeryidle_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 1 ? "activeGameText" : ""}`}>BAKERY IDLE!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 2 ? "activeGame" : ""}`} src={require("../assets/gameLogos/dealerswar_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 2 ? "activeGameText" : ""}`}>DEALERS WAR!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 3 ? "activeGame" : ""}`} src={require("../assets/gameLogos/fitthemall_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 3 ? "activeGameText" : ""}`}>FIT THEM ALL!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 4 ? "activeGame" : ""}`} src={require("../assets/gameLogos/justbeauty_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 4 ? "activeGameText" : ""}`}>JUST BEAUTY!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 5 ? "activeGame" : ""}`} src={require("../assets/gameLogos/mypitstop_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 5 ? "activeGameText" : ""}`}>MY PITSTOP!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 6 ? "activeGame" : ""}`} src={require("../assets/gameLogos/stealthdet_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 6 ? "activeGameText" : ""}`}>STEALTH DETECTIVE!</div>
                            </div>
                        </div>
                        <div>
                            <div className="game-in-games-list">
                                <a href="https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps" target="_blank" rel="noreferrer">
                                    <img className={`main-game ${this.state.activeIndex === 7 ? "activeGame" : ""}`} src={require("../assets/gameLogos/pestoffice_weblogo@2x.png")}/>
                                    <MobileStoreButton
                                        store="ios"
                                        url={'https://apps.apple.com/us/developer/kaan-karavar/id1559627097?see-all=i-phonei-pad-apps'}
                                        linkProps={{ title: 'iOS Store Button' }}
                                        className='ios-store-button'
                                    />
                                </a>
                                <div className={`main-game-text green-text ${this.state.activeIndex === 7 ? "activeGameText" : ""}`}>PEST OFFICE!</div>
                            </div>
                        </div>
                </Slider>
                <Button variant="transparent" onClick={() => this.slider.current.slickPrev()} className="arrow-button arrow-prev"><img src={require("../assets/leftarrow.png")}/></Button>
                <Button variant="transparent" onClick={() => this.slider.current.slickNext()} className="arrow-button arrow-next"><img src={require("../assets/rightarrow.png")}/></Button>
                </div>
            </div>
        );
    }
}

export default OurGames;
