import React from "react";

import "../styles/home.css";
import "../styles/responsive/responsive_home.css"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div id="home" name="home" className="home-page">
                <div id="home-left">
                    <div className="home-welcome-text">
                        WELCOME
                    </div>
                    <div className="home-title-text">
                        WE LOVE <span className="green-text"> GAMES! </span> 
                        <br /> DO YOU? 
                        
                        <img className="home-alien" src={require("../assets/alien.png")}/>

                        <br/>
                    </div>
                    <div className="home-description-text">
                        Our games have a secret ingredient: <span className="red-text"> Love. </span> <br/>
                        They also include, sugar, spice <br/>
                        and everything nice! 
                    </div>
                    <div>
                        <a href="#ourgames"><img className="home-get-started" src={require("../assets/get_started.png")} /> </a>
                        <img className="home-get-started-hand" src={require("../assets/hand.png")} />
                    </div>
                </div>
                <div id="home-right">
                    <img className="home-image" src={require("../assets/furtles.png")}/>
                </div>
            </div>
        );
    }
}

export default Home;
