import './App.css';
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import StickyHeader from './pages/stickyHeader';
import Home from './pages/home';
import OurGames from './pages/ourGames';
import AboutUs from './pages/aboutUs';
import Contact from './pages/contact';
import Store from './pages/store';
import Footer from './pages/footer';
import Privacy from './pages/privacy';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <StickyHeader />
        <Routes>
          <Route path="/" element={
            <div>
              <Home />
              <OurGames />
              <AboutUs />
              <Contact />
            </div>
          } />
          <Route path="/store" element={<Store />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
