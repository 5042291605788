import React from "react";
import PropTypes from "prop-types";
import Scrollspy from 'react-scrollspy'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { GiHamburgerMenu } from 'react-icons/gi';
import { scroller } from 'react-scroll'

import "../styles/stickyHeader.css";
import "../styles/responsive/responsive_stickyHeader.css"

import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                history={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

class StickyHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { screenWidth: null };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);

        this.updateWindowDimensions();
        this.jumpToHash();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
        window.observe();
    }

    jumpToHash = () => {
        const hash = this.props.history.location.hash;
        console.log(this.props.history);
        console.log(hash);
        if (hash) {
            scroller.scrollTo(hash.replace("#", ""), { smooth: true, duration: 100 });
        }
    }

    render() {
        if (this.state.screenWidth > 600)
            return (
                <Scrollspy
                    className="scrollspy Sticky-Header-Navigation"
                    items={[null, 'home', 'ourgames', 'aboutus', 'contact']}
                    offset={-350}
                    currentClassName="active">
                    <a href="/#top"><img id="logo" src={require("../assets/logo.png")} /></a>
                    <li><a href="/#top">HOME</a></li>
                    <li><a href="/#ourgames">GAMES</a></li>
                    <li><a href="/#aboutus">ABOUT US</a></li>
                    <li><a href="/#contact">CONTACT</a></li>
                    <li className="store-button"><a href="/store">STORE</a></li>
                    {/* <li className="language-button"><a href="#contact">LANGUAGE</a></li> */}
                </Scrollspy>
            );
        else
            return (
                <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" className="navbar-header">
                    <Container>
                        <a href="#top"><img id="logo" src={require("../assets/logo.png")} /></a>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"><GiHamburgerMenu size={35} /></Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto navigation-list" justify fill>
                                <Nav.Link href="/#top">HOME</Nav.Link>
                                <Nav.Link href="/#ourgames">GAMES</Nav.Link>
                                <Nav.Link href="/#aboutus">ABOUT US</Nav.Link>
                                <Nav.Link href="/#contact">CONTACT</Nav.Link>
                                <Nav.Link href="/store">STORE</Nav.Link>
                                {/* <Nav.Link>TR</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            )
    }
}


StickyHeader.propTypes = {

}

StickyHeader.defaultProps = {

}


export default withRouter(StickyHeader);
