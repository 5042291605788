import React from "react";
import PropTypes from "prop-types";

import "../styles/store.css";

class Store extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (<div id="store">
            <img src={require("../assets/store.png")} />

            <div className="text">
                <div className="store-description green-text">WE'RE FILLING THE SHELVES</div>
                <div className="store-title green-text">COMING SOON!</div>
            </div>
        </div>);
    }
}

Store.propTypes = {

}

Store.defaultProps = {

}


export default Store;
