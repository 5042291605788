import React from "react";
import PropTypes from "prop-types";
import {scroller} from 'react-scroll'

import "../styles/footer.css";
import "../styles/responsive/responsive_footer.css"


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
        <div id="footer" className="footer">
            <div className="up">
                <div id="head-icon">
                    <img className="logo" src={require("../assets/logohead.png")} onClick={() => scroller.scrollTo('home', {smooth: true, offset: -350, duration: 100})} />
                </div>    
                <div id="support" className="support">
                    <label className="support-title">SUPPORT</label> <br/>
                    <label onClick={() => scroller.scrollTo('contact', {smooth: true, offset: -350, duration: 100})} className="support-text">Contact</label> <br/>
                    <a href="/privacy"><label className="support-text">Privacy Policy</label></a> <br/>
                    <a href="/privacy"><label className="support-text">Terms of Service & Conditions</label></a>
                </div>
                <div id="socialmedia" className="socialmedia">
                    <label className="socialmedia-title">SOCIAL MEDIA</label> <br/>
                    <div className="social-media-icons">
                        <a href="https://www.linkedin.com/company/furtlegame" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/linkedin.png")}/></a>
                        <a href="https://www.youtube.com/channel/UCd4PmwRzJdK4xtlVKsLlcxw" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/youtube.png")}/></a>
                        <a href="https://www.facebook.com/furtlegame/" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/facebook.png")}/></a>
                        <a href="https://twitter.com/furtlegame" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/twitter.png")}/></a>
                        <a href="https://discord.gg/nFuQf5pTqE" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/discord.png")}/></a>
                        <a href="https://www.tiktok.com/@furtlegame" target="_blank" rel="noreferrer"><img className="social-media-icon" src={require("../assets/tiktok.png")}/></a>
                    </div>
                    <label className="follow-text">FOLLOW US!</label>
                </div>
            </div>
            <div className="bottom">
                <hr className="bottom-line" />
                <label className="copyright-text">Copyright © 2022 - Furtle Game. All rights reserved.</label> 
            </div>
        </div>);
    }
}

Footer.propTypes = {

}

Footer.defaultProps = {

}


export default Footer;
